import { useNotification } from "@/composables/useNotification";
import { ServiceOrderState } from "../types/state";
import { IRootState } from "@/types/state";
import { ActionContext } from "vuex";
import { FormMode } from "@/consts/form";
import { defaultFilterServiceOrder, defaultServiceOrder } from "../consts";
import { IFilterServiceOrder, IPayloadByServices, ServiceOrder } from "../types/service_order";
import { Service } from "@/modules/services/types/service";
import axiosAuth from "@/config/axios";
import { endPoints } from "@/config/api";
import { DefaultResponse } from "@/types/response";


const { notifySuccess, notifyError } = useNotification();


const state: () => ServiceOrderState = () => ({
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    showFormDialog: false,
    showFilterDialog: false,
    selectedServiceOrder: defaultServiceOrder,
    formMode: FormMode.ADD,
    servicesSelected: [] as Service[],
    clientName: '',
    clientSelectedEdit: {} as Client,
    serviceOrders: [] as ServiceOrder[],
    filterServiceOrder: Object.assign({}, defaultFilterServiceOrder)


});

const mutations = {
    SET_FORM_DIALOG_OPEN(state: ServiceOrderState, isOpen: boolean) {
        state.showFormDialog = isOpen;
    },

    SET_SELECTED_SERVICE_ORDER(state: ServiceOrderState, serviceOrder: ServiceOrder) {
        state.selectedServiceOrder = Object.assign({}, serviceOrder);
        if (state.formMode === FormMode.ADD){
            state.selectedServiceOrder.employees = [];
        }
    },
    SET_FORM_MODE(state: ServiceOrderState, mode: FormMode) {
        state.formMode = mode;
    },

    SET_SERVICES_SELECTED(state: ServiceOrderState, services: Service[]) {
        state.servicesSelected = services;
    },

    SET_CLIENT_NAME(state: ServiceOrderState, clientName: string) {
        state.clientName = clientName;
    },

    SET_CLIENT_SELECTED_EDIT(state: ServiceOrderState, client: Client) {
        state.clientSelectedEdit = client;
    },

    SET_SERVICE_ORDERS(state: ServiceOrderState, payload: { services: ServiceOrder[] }) {
        state.serviceOrders = payload.services;
        console.log(state.serviceOrders)
    },

    SET_TOTAL(state: ServiceOrderState, payload: { total: number }) {
        state.pagination.total = payload.total;
    },

    SET_FILTER_DIALOG_OPEN(state: ServiceOrderState, isOpen: boolean) {
        state.showFilterDialog = isOpen;
    },
    SET_FILTER_SERVICE_ORDER(state: ServiceOrderState, filterServiceOrder: IFilterServiceOrder) {
        state.filterServiceOrder = filterServiceOrder;
    }



};

const actions = {
    openFilterDialog({ commit }: ActionContext<ServiceOrderState, IRootState>) {
        commit('SET_FILTER_DIALOG_OPEN', true);

    },
    closeFilterDialog({ commit }: ActionContext<ServiceOrderState, IRootState>) {
        commit('SET_FILTER_DIALOG_OPEN', false);
    },
    clearFilter({ commit }: ActionContext<ServiceOrderState, IRootState>) {
        commit('SET_FILTER_SERVICE_ORDER', Object.assign({}, defaultFilterServiceOrder));
    },
    openFormDialog({ state,commit }: ActionContext<ServiceOrderState, IRootState>) {
        console.log('openFormDialog', state.selectedServiceOrder)
        commit('SET_FORM_MODE', FormMode.ADD);
        commit('SET_SELECTED_SERVICE_ORDER', Object.assign({}, defaultServiceOrder));
        commit('SET_FORM_DIALOG_OPEN', true);
    },
    openFormDialogByServices({ state,commit }: ActionContext<ServiceOrderState, IRootState>, payload: IPayloadByServices) {
        console.log('openFormDialogByServices', payload)
        const serviceOrder = Object.assign({}, defaultServiceOrder);
        serviceOrder.serviceDate = new Date(`${payload.date}T00:00:00`);
        serviceOrder.clientId = payload.clientId;
        serviceOrder.clientServiceSchedule = payload.clientServiceSchedule;
        commit('SET_CLIENT_NAME', payload.clientName);
        commit('SET_SELECTED_SERVICE_ORDER', serviceOrder);
        commit('SET_FORM_MODE', FormMode.ADD);
        commit('SET_FORM_DIALOG_OPEN', true);
        commit('SET_SERVICES_SELECTED', payload.services);
        console.log(state.servicesSelected)
    },

    closeFormDialog({ commit }: ActionContext<ServiceOrderState, IRootState>) {
        commit('SET_FORM_DIALOG_OPEN', false);
        commit('SET_CLIENT_NAME', '');
        commit('SET_SERVICES_SELECTED', []);
        commit('SET_SELECTED_SERVICE_ORDER', Object.assign({}, defaultServiceOrder));
    },

    openEditDialog({ commit, state }: ActionContext<ServiceOrderState, IRootState>, payload: { service_order: any }) {
        console.log('openEditDialog', payload.service_order.employees[0].name)
        const employees = payload.service_order.employees.map((employee: any) => employee.name);
        console.log(employees)
        const servicesSelecteds = payload.service_order.services.map((serviceOrderServices: any) => {
            console.log(serviceOrderServices)
            return {
                id: serviceOrderServices.service.id,
                description: serviceOrderServices.service.description,
                baseValue: serviceOrderServices.service.baseValue,
                quantity: serviceOrderServices.quantity,
            }
        });
        commit('SET_FORM_MODE', FormMode.EDIT);
        const serviceOrder: ServiceOrder = {
            serviceDate: new Date(payload.service_order.serviceDate),
            clientId: payload.service_order.client.id,
            searchFor: payload.service_order.searchFor,
            apartment: payload.service_order.apartment,
            number: payload.service_order.number,
            baseValue: payload.service_order.baseValue,
            totalValue: payload.service_order.totalValue,
            status: payload.service_order.status,
            employees: employees,
            services: payload.service_order.services,
            observations: payload.service_order.observations,
            createdAt: new Date(payload.service_order.createdAt),
            createdBy: payload.service_order.createdBy,
            updatedAt: payload.service_order.updatedAt ? new Date(payload.service_order.updatedAt) : null,
            updatedBy: payload.service_order.updatedBy,
            id: payload.service_order.id,
            clientServiceSchedule: payload.service_order.clientServiceSchedule
        }
        console.log(serviceOrder)
        commit('SET_SELECTED_SERVICE_ORDER', serviceOrder);
        commit('SET_SERVICES_SELECTED', servicesSelecteds);
        console.log(payload.service_order.client)
        commit('SET_CLIENT_SELECTED_EDIT', payload.service_order.client);
        commit('SET_CLIENT_NAME', payload.service_order.client.name);
        commit('SET_FORM_DIALOG_OPEN', true);
    },

    openViewDialog({ commit, state }: ActionContext<ServiceOrderState, IRootState>, payload: { service_order: any }) {
        console.log('openViewDialog', payload.service_order.employees[0].name)
        const employees = payload.service_order.employees.map((employee: any) => employee.name);
        console.log(employees)
        const servicesSelecteds = payload.service_order.services.map((serviceOrderServices: any) => {
            console.log(serviceOrderServices)
            return {
                id: serviceOrderServices.service.id,
                description: serviceOrderServices.service.description,
                baseValue: serviceOrderServices.service.baseValue,
                quantity: serviceOrderServices.quantity,
            }
        });
        commit('SET_FORM_MODE', FormMode.VIEW);
        const serviceOrder: ServiceOrder = {
            serviceDate: new Date(payload.service_order.serviceDate),
            clientId: payload.service_order.client.id,
            searchFor: payload.service_order.searchFor,
            apartment: payload.service_order.apartment,
            number: payload.service_order.number,
            baseValue: payload.service_order.baseValue,
            totalValue: payload.service_order.totalValue,
            status: payload.service_order.status,
            employees: employees,
            services: payload.service_order.services,
            observations: payload.service_order.observations,
            createdAt: new Date(payload.service_order.createdAt),
            createdBy: payload.service_order.createdBy,
            updatedAt: payload.service_order.updatedAt ? new Date(payload.service_order.updatedAt) : null,
            updatedBy: payload.service_order.updatedBy,
            id: payload.service_order.id,
            clientServiceSchedule: payload.service_order.clientServiceSchedule
        }
        console.log(serviceOrder)
        commit('SET_SELECTED_SERVICE_ORDER', serviceOrder);
        commit('SET_SERVICES_SELECTED', servicesSelecteds);
        commit('SET_CLIENT_NAME', payload.service_order.client.name);
        commit('SET_FORM_DIALOG_OPEN', true);
    },

    async fetchServiceOrders({ commit, dispatch, state }: ActionContext<ServiceOrderState, IRootState>) {
        console.log('fetchServiceOrders')
        try {
            const params: any = {};

            for (const key in state.filterServiceOrder) {
                const value = state.filterServiceOrder[key as keyof typeof state.filterServiceOrder];
                if (value) {
                    params[key] = value;
                }
            }

            params.page = state.pagination.page;
            params.itemsPerPage = state.pagination.itemsPerPage;

            const response = await axiosAuth.get<DefaultResponse<ServiceOrder>>(`${endPoints.service_orders}`, { params });
            commit('SET_SERVICE_ORDERS', { services: response.data.data });
            dispatch('closeFilterDialog');
            commit('SET_TOTAL', { total: response.data.total });
        } catch (error) {
            notifyError('Erro ao buscar serviços');
        }

    },

    async saveServiceOrder({ commit, dispatch, state }: ActionContext<ServiceOrderState, IRootState>) {
        console.log('saveServiceOrder', state.selectedServiceOrder)
        try {
            await axiosAuth.post(endPoints.service_orders, state.selectedServiceOrder);
            notifySuccess('Ordem de serviço salva com sucesso');
            commit('SET_FORM_DIALOG_OPEN', false);
            dispatch('fetchServiceOrders');
            dispatch('closeFormDialog');

        } catch (error) {
            console.log(error)
            notifyError('Erro ao salvar a ordem de serviço');
        }
    },

    async updateServiceOrder({ commit, dispatch, state }: ActionContext<ServiceOrderState, IRootState>) {
        state.selectedServiceOrder.clientId = state.clientSelectedEdit.id; 
        console.log('updateServiceOrder', state.selectedServiceOrder)   
        try {
            await axiosAuth.put(`${endPoints.service_orders}/${state.selectedServiceOrder.id}`, state.selectedServiceOrder);
            notifySuccess('Ordem de serviço atualizada com sucesso');
            commit('SET_FORM_DIALOG_OPEN', false);
            dispatch('fetchServiceOrders');
            dispatch('closeFormDialog');
        } catch (error) {
            notifyError('Erro ao atualizar a ordem de serviço');
        }   
    },
    
    async openBIRTReport({state}: ActionContext<ServiceOrderState, IRootState>, payload: { serviceOrderId: string }) {
        const url = `${process.env.VUE_APP_BIRT_BASE_URL}/preview?__report=${process.env.VUE_APP_BIRT_BASE_DIR}/order_service.rptdesign&__format=pdf&order_id=${payload.serviceOrderId}`;
        window.open(url, '_blank');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};