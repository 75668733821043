import { IFilterServiceOrder, ServiceOrder } from "./types/service_order";


export const defaultServiceOrder: ServiceOrder = {
    id: '',
    searchFor: '',
    apartment: null,
    number: 0,
    baseValue: null,
    totalValue: null,
    status: '',
    serviceDate: null,
    observations: null,
    clientId: '',
    employees: [],
    services: [],
    createdAt: new Date(),
    createdBy: '',
    updatedAt: null,
    updatedBy: null,
    clientServiceSchedule:'',
  }

  export const defaultFilterServiceOrder: IFilterServiceOrder = {
    clientId: '',
    serviceDate: null,
    number: '',
    docClient: '',
    cepClient: ''
  }

