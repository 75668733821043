import { ClientAddresses } from "./types/client_addresses";

export enum FormMode {
    Add = "add",
    Edit = "edit",
    View = "view",
}



export const defaultClientAddresses: ClientAddresses = {
    id: '', 
    addressCep: '',
    addressCity: '',
    addressNeighborhood: '',
    address: '',
    addressNumber: '',
    addressState: '',
    addressComplement: '',
    clientId: '', 
    latitude: undefined,  
    longitude: undefined,
  };

  export const defaultFilterClientAddresses = {
    name: '', 
    latitude: undefined,  
    longitude: undefined, 
    currentClientId: '',
  };