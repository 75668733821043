import { ActionContext } from 'vuex';
import { API_URL } from '@/config/api';
import { useNotification } from '@/composables/useNotification';
import { ClientEmployeeState } from '../types/state';
import { ClientEmployee, ClientEmployeeTransport } from '../types/client_employee';
import { DefaultResponse } from '@/types/response';
import axiosAuth from '@/config/axios';
import { ICreateClientEmployeeEvent, IEventClientSchedule, IEventClientScheduleDTO } from '../types/events';
import { defaultClient } from '@/modules/client/consts';
import { defaultEmployee } from '@/modules/employee/consts';
import { FormMode } from '@/consts/form';
import { IRootState } from '@/types/state';
import { getDistinctDaysByEvents } from './events';

const { notifySuccess, notifyError } = useNotification();

const defaultClientEmployeeEvent: ICreateClientEmployeeEvent = {
  start: '',
  end: '',
  days: [],
  employeeIds: [],
  clientId: '',
  addressId: '',
  scheduleHash: '',
  clientSchedulesId: [],
  clientEmployeesIds: []
}
const defaultClientEmployee: ClientEmployee = {
  id: '',
  status: 1,
  client: defaultClient,
  employee: defaultEmployee,
  clientEmployeeTransports: [],
}

const state: () => ClientEmployeeState = () => ({
  clientsEmployees: [],
  days: [0, 1, 2, 3, 4],
  selectedClientEmployee: Object.assign({}, defaultClientEmployee),
  dialogCreateClientEmployee: false,
  dialogAddClientEmployeeTransport: false,
  filterCalendarAddress: null,
  events: [],
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  selectedClientEmployeeEvent: Object.assign({}, defaultClientEmployeeEvent),
  oldClientEmployeeEvent: JSON.parse(JSON.stringify(defaultClientEmployeeEvent)),
  oldClientEmployees: [],
  formMode: FormMode.ADD

});


const mutations = {
  SET_CLIENTS_EMPLOYEES(state: ClientEmployeeState, payload: { clientsEmployees: ClientEmployee[] }) {
    state.clientsEmployees = payload.clientsEmployees;
  },
  SET_CLIENT_EMPLOYEE_EVENTS(state: ClientEmployeeState, payload: { events: IEventClientSchedule[] }) {
    state.events = payload.events;
  },
  SHOW_DIALOG_CREATE_CLIENT_EMPLOYEE(state: ClientEmployeeState, payload: { show: boolean, start: string, end: string }) {
    state.dialogCreateClientEmployee = payload.show;
  },
  SHOW_DIALOG_ADD_CLIENT_EMPLOYEE_TRANSPORT(state: ClientEmployeeState, payload: { show: boolean }) {
    state.dialogAddClientEmployeeTransport = payload.show;
  },
  SET_SELECTED_CLIENT_EMPLOYEE(state: ClientEmployeeState, payload: { clientEmployee: ClientEmployee }) {
    state.selectedClientEmployee = payload.clientEmployee;
  },
  SET_CLIENT_EMPLOYEE_EVENT(state: ClientEmployeeState, payload: { event: ICreateClientEmployeeEvent }) {
    state.selectedClientEmployeeEvent = payload.event;
  },
  SET_OLD_CLIENT_EMPLOYEE_EVENT(state: ClientEmployeeState, payload: { event: ICreateClientEmployeeEvent }) {
    state.oldClientEmployeeEvent = payload.event;
  },
  SET_OLD_CLIENT_EMPLOYEES(state: ClientEmployeeState, payload: { employees: string[] }) {
    state.oldClientEmployees = payload.employees;
  },
  SET_FILTER_CALENDAR_ADDRESS(state: ClientEmployeeState, payload: { addressId: string }) {
    state.filterCalendarAddress = payload.addressId;
  },
  SET_MODE(state: ClientEmployeeState, payload: { mode: FormMode }) {
    state.formMode = payload.mode;
  },
  SET_DAYS(state: ClientEmployeeState, payload: { days: number[] }) {
    state.days = payload.days;
  }


};

const getters = {

  getOldClientEmployees: (state: ClientEmployeeState) => {
    return state.oldClientEmployees;
  },

}


const actions = {
  openTransportDialog({ commit, state }: ActionContext<ClientEmployeeState, IRootState>, payload: { clientEmployeeSelected: ClientEmployee, showDialog: boolean }) {
    commit('SET_SELECTED_CLIENT_EMPLOYEE', { clientEmployee: payload.clientEmployeeSelected })
    commit('SHOW_DIALOG_ADD_CLIENT_EMPLOYEE_TRANSPORT', { show: payload.showDialog })
  },
  closeTransportDialog({ commit }: ActionContext<ClientEmployeeState, IRootState>) {
    commit('SET_SELECTED_CLIENT_EMPLOYEE', { clientEmployee: Object.assign({}, defaultClientEmployee) })
    commit('SHOW_DIALOG_ADD_CLIENT_EMPLOYEE_TRANSPORT', { show: false })
  },
  async fetchClientsEmployees({ commit }: ActionContext<ClientEmployeeState, IRootState>, payload: { clientId: string, employeeId: string, screen: string }) {
    try {
      let url = `${API_URL}/client_employee`;
      if (payload.screen == 'client') {
        url += `?clientId=${payload.clientId}`;
      }
      if (payload.screen == 'employee') {
        url += `?employeeId=${payload.employeeId}`;
      }
      const response = await axiosAuth.get<DefaultResponse<ClientEmployee>>(`${url}`);
      commit('SET_CLIENTS_EMPLOYEES', { clientsEmployees: response.data.data });
    } catch (error) {
      notifyError('Erro ao buscar os dados');
      console.error('Erro ao buscar os dados:', error);
    }
  },
  async fetchClientEmployeeEvents({ commit }: ActionContext<ClientEmployeeState, IRootState>, payload: { clientId: string, employeeId: string, screen: string }) {
    try {
      let url = `${API_URL}/client_employee/events`;
      if (payload.screen == 'employee') {
        url += `?employeeId=${payload.employeeId}&screen=${payload.screen}`;
      }
      if (payload.screen == 'client') {
        url += `?clientId=${payload.clientId}&screen=${payload.screen}`;
      }
      const response = await axiosAuth.get<DefaultResponse<IEventClientScheduleDTO>>(`${url}`);
      const data = response.data.data.map((event) => {
        return {
          ...event,
          start: new Date(event.start),
          end: new Date(event.end)
        }
      })
      console.log('to aqui', data)
      const daysEvents = getDistinctDaysByEvents(data);
      commit('SET_DAYS', { days: daysEvents });
      commit('SET_CLIENT_EMPLOYEE_EVENTS', { events: data });
    } catch (error) {
      notifyError('Erro ao buscar eventos');
    }
  },
  async showDialogCreateClientEmployee({ commit }: ActionContext<ClientEmployeeState, IRootState>, payload: { show: boolean, day: number, start: string, end: string }) {
    const createClientEmployee = Object.assign({}, defaultClientEmployeeEvent);
    console.log('to aqui', createClientEmployee)
    createClientEmployee.start = payload.start;
    createClientEmployee.end = payload.end;
    createClientEmployee.days = [payload.day];
    commit('SET_MODE', { mode: FormMode.ADD });
    commit('SHOW_DIALOG_CREATE_CLIENT_EMPLOYEE', payload);
    commit('SET_CLIENT_EMPLOYEE_EVENT', { event: createClientEmployee });
  },
  async showDialogUpdateClientEmployee({ state, commit }: ActionContext<ClientEmployeeState, any>, payload: { show: boolean, days: number[], start: string, end: string, hash: string, clientSchedulesId: string[], clientEmployeesIds: string[], employeesSelecteds: string[] }) {
    const updateClientEmployee = Object.assign({}, defaultClientEmployeeEvent);
    updateClientEmployee.start = payload.start;
    updateClientEmployee.end = payload.end;
    updateClientEmployee.days = payload.days;
    updateClientEmployee.scheduleHash = payload.hash;
    updateClientEmployee.clientSchedulesId = payload.clientSchedulesId;
    updateClientEmployee.clientEmployeesIds = payload.clientEmployeesIds;
    commit('SET_MODE', { mode: FormMode.EDIT });
    commit('SHOW_DIALOG_CREATE_CLIENT_EMPLOYEE', payload);

    commit('SET_OLD_CLIENT_EMPLOYEES', { employees: payload.employeesSelecteds });
    commit('SET_OLD_CLIENT_EMPLOYEE_EVENT', { event: JSON.parse(JSON.stringify(updateClientEmployee)) });
    commit('SET_CLIENT_EMPLOYEE_EVENT', { event: updateClientEmployee });

  },

  async deleteClientEmployeeEvent({ dispatch,commit, state, rootGetters }: ActionContext<ClientEmployeeState, IRootState>, payload: { screen: string }) {
    try {
      const currentClientId =  rootGetters['clients/getCurrentClientId'];
      const currentEmployeeId = rootGetters['employees/getCurrentEmployeeId'];
      await axiosAuth.delete(`${API_URL}/client_schedule/${state.selectedClientEmployeeEvent.scheduleHash}`);
      notifySuccess('Evento deletado com sucesso');
      dispatch('fetchClientEmployeeEvents', { clientId: currentClientId, employeeId: currentEmployeeId, screen: payload.screen });
      commit('SHOW_DIALOG_CREATE_CLIENT_EMPLOYEE', { show: false });
      commit("client_employee/SET_OLD_CLIENT_EMPLOYEES", []);
    } catch (error) {
      notifyError('Erro ao deletar evento');
      console.error('Erro ao deletar evento:', error);
    }

  },

  async updateClientEmployeeEvents({ dispatch, state, commit, rootGetters }: ActionContext<ClientEmployeeState, IRootState>, payload: { employeesIds?: string[], clientId?: string, addressSelectedId?: string, screen: string }) {
    const currentClientId = payload.clientId ? payload.clientId : rootGetters['clients/getCurrentClientId'];
    const addressSelected = payload.addressSelectedId ? payload.addressSelectedId : rootGetters['client_addresses/getAddressSelectedId'];
    const employeesIds = payload.employeesIds ? payload.employeesIds : [rootGetters['employees/getCurrentEmployeeId']];

    const data = {
      isSameEvent: true,
      isSameEmployees: true,
      employeesIds: employeesIds,
      clientEmployeesIds: state.selectedClientEmployeeEvent.clientEmployeesIds,
      daysOfWeek: state.selectedClientEmployeeEvent.days,
      scheduleHash: state.selectedClientEmployeeEvent.scheduleHash,
      clientSchedulesIds: state.selectedClientEmployeeEvent.clientSchedulesId,
      startHour: state.selectedClientEmployeeEvent.start,
      finishHour: state.selectedClientEmployeeEvent.end,
      clientId: currentClientId,
      clientAddressId: addressSelected,
    }
    const isSameEvent = JSON.stringify(state.selectedClientEmployeeEvent) === JSON.stringify(state.oldClientEmployeeEvent);
    if (!isSameEvent) {
      data.isSameEvent = false;
    }
    const isSameEmployees = JSON.stringify(payload.employeesIds) === JSON.stringify(state.oldClientEmployees);
    if (!isSameEmployees) {
      data.isSameEmployees = false;
    }

    try {
      await axiosAuth.put(`${API_URL}/client_schedule`, data);
      notifySuccess('Clientes e funcionários vinculados com sucesso');
      dispatch('fetchClientsEmployees', { clientId: currentClientId, employeeId: employeesIds[0], screen: payload.screen });
      dispatch('fetchClientEmployeeEvents', { clientId: currentClientId, employeeId: employeesIds[0], screen: payload.screen });
      commit('SHOW_DIALOG_CREATE_CLIENT_EMPLOYEE', { show: false });

    } catch (error) {
      console.error('Erro ao criar agendamento para o cliente:', error);
      notifyError('Erro');
    }

  },
  async saveClientEmployeeEvents({ dispatch, state, rootGetters }: ActionContext<ClientEmployeeState, IRootState>, payload: { employeesIds?: string[] }) {
    const currentClientId = rootGetters['clients/getCurrentClientId'];
    const addressSelected = rootGetters['client_addresses/getAddressSelected'];

    const data = {
      daysOfWeek: state.selectedClientEmployeeEvent.days,
      startHour: state.selectedClientEmployeeEvent.start,
      finishHour: state.selectedClientEmployeeEvent.end,
      clientId: currentClientId,
      clientAddressId: addressSelected.id,

    }
    try {
      const response = await axiosAuth.post(`${API_URL}/client_schedule`, data);
      dispatch('saveClientEmployee', { employeesIds: payload.employeesIds, clientSchedulesId: response.data })
    } catch (error) {
      console.error('Erro ao criar agendamento para o cliente:', error);
      notifyError('Erro');
    }
  },
  async saveClientEmployee({ dispatch, commit, state, rootGetters }: ActionContext<ClientEmployeeState, IRootState>, payload: { employeesIds?: string[], clientId?: string, addressSelectedId?: string, screen: string }) {
    const currentClientId = rootGetters['clients/getCurrentClientId'];
    const addressSelected = rootGetters['client_addresses/getAddressSelected'];
    const currentEmployeeId = rootGetters['employees/getCurrentEmployeeId'];
    const data = {
      employeeIds: payload.employeesIds ? payload.employeesIds : [currentEmployeeId],
      clientSchedule: {
        daysOfWeek: state.selectedClientEmployeeEvent.days, // ok
        startHour: state.selectedClientEmployeeEvent.start, // ok
        finishHour: state.selectedClientEmployeeEvent.end,  //ok
        clientId: payload.clientId ? payload.clientId : currentClientId, //ok
        clientAddressId: payload.addressSelectedId ? payload.addressSelectedId : addressSelected.id, //ok
      }
    }
    console.log('to aqui', data)
    try {
      await axiosAuth.post(`${API_URL}/client_employee`, data);
      notifySuccess('Clientes e funcionários vinculados com sucesso');
      dispatch('fetchClientsEmployees', { clientId: currentClientId, employeeId: currentEmployeeId, screen: payload.screen });

      dispatch('fetchClientEmployeeEvents', { clientId: currentClientId, employeeId: currentEmployeeId, screen: payload.screen });
      commit('SHOW_DIALOG_CREATE_CLIENT_EMPLOYEE', { show: false });
    } catch (error) {
      console.error('Erro ao criar endereço para o cliente:', error);
      notifyError('Erro');
    }
  },


  async addTransportToClientEmployee(
    { state, dispatch }: ActionContext<ClientEmployeeState, IRootState>,
    payload: ClientEmployeeTransport
  ) {
    try {
      const url = `${API_URL}/client_employee/${state.selectedClientEmployee.id}/transport`;

      const transportData = {
        bus: payload.bus,
        type: payload.type,
        value: payload.value,
      };

      await axiosAuth.post<DefaultResponse<ClientEmployeeTransport>>(url, transportData);
      dispatch('fetchClientsEmployees', { clientId: state.selectedClientEmployee.client.id, employeeId: '', screen: 'client' });
      notifySuccess('Transporte adicionado com sucesso');

    } catch (error) {
      notifyError('Erro ao adicionar transporte');
      console.error('Erro ao adicionar transporte:', error);
    }
  }
};



export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
