import { ActionContext } from 'vuex';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { useNotification } from '@/composables/useNotification';
import { ClientsServicesSchedulesState } from '../types/state';
import { ClientsServicesSchedules } from '../types/clientsServiceSchedules';
import { DefaultResponse } from '@/types/response';
import { defaultClientServices } from '@/modules/client_services/consts';
import { formatDateToString, getDateFromUrl } from '@/utils/date';
import { IRootState } from '@/types/state';
const { notifySuccess, notifyError } = useNotification();

const defaultClientsServicesSchedules = {
    id: '',
    createdAt: new Date(),
    status: 0,
    serviceOrderId: '',
    clientServiceId: '',
    date: new Date(),
    clientService: defaultClientServices,
    isFirst: false,
    itemsSelected: [],
}

const state: () => ClientsServicesSchedulesState = () => ({
    servicesSchedules: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
    },
    itemsSelected: [],
    loading: false,
    selectedClientsServicesSchedules: Object.assign({}, defaultClientsServicesSchedules),
    showRescheduleDialog: false,
    showScheduleDialog: false,
    nextExecutionDate: new Date(),
});


const mutations = {

    SET_SERVICES_SCHEDULES(state: ClientsServicesSchedulesState, servicesSchedules: ClientsServicesSchedules[]) {
        state.servicesSchedules = servicesSchedules;
    },

    SET_RESCHEDULE_DIALOG(state: ClientsServicesSchedulesState, payload: { show: boolean }) {
        state.showRescheduleDialog = payload.show
    },
    SET_SCHEDULE_DIALOG(state: ClientsServicesSchedulesState, payload: { show: boolean }) {
        state.showScheduleDialog = payload.show
    },

    SET_NEXT_EXECUTION_DATE(state: ClientsServicesSchedulesState, payload: { date: Date }) {
        state.nextExecutionDate = new Date(payload.date);
    },

    SET_SELECTED_CLIENTS_SERVICES_SCHEDULES(state: ClientsServicesSchedulesState, payload: { selectedClientsServicesSchedules: ClientsServicesSchedules }) {
        state.selectedClientsServicesSchedules = Object.assign({}, payload.selectedClientsServicesSchedules)
        state.selectedClientsServicesSchedules.date = new Date(payload.selectedClientsServicesSchedules.date)
        state.selectedClientsServicesSchedules.date.setDate(state.selectedClientsServicesSchedules.date.getDate() + 2);
    },
    SET_DEFAULT_SELECTED_CLIENTS_SERVICES_SCHEDULES(state: ClientsServicesSchedulesState) {
        state.selectedClientsServicesSchedules = Object.assign({}, defaultClientsServicesSchedules)
    },
    SET_ITEMS_SELECTED(state: ClientsServicesSchedulesState, payload: { itemsSelected: ClientsServicesSchedules[] }) {
        state.itemsSelected = payload.itemsSelected;
    },
    SET_LOADING(state: ClientsServicesSchedulesState, payload: { loading: boolean }) {
        state.loading = payload.loading;
    },

};

const actions = {
    openRescheduleDialog({ commit }: ActionContext<ClientsServicesSchedulesState, IRootState>, payload: { selectedClientsServicesSchedules: ClientsServicesSchedules[] }) {
        commit('SET_ITEMS_SELECTED', {itemsSelected: payload.selectedClientsServicesSchedules});
        commit('SET_RESCHEDULE_DIALOG', { show: true });
    },
    closeRescheduleDialog({ commit }: ActionContext<ClientsServicesSchedulesState, IRootState>) {
        commit('SET_DEFAULT_SELECTED_CLIENTS_SERVICES_SCHEDULES');
        commit('SET_RESCHEDULE_DIALOG', { show: false });
    },
    openFinishConfirmationDialog({ commit, state }: ActionContext<ClientsServicesSchedulesState, IRootState>, payload: { selectedClientsServicesSchedules: ClientsServicesSchedules[] }) {
        console.log('to aqui')
        commit('SET_ITEMS_SELECTED', {itemsSelected: payload});
        commit('SET_SCHEDULE_DIALOG', { show: true });
        const clientService = state.selectedClientsServicesSchedules.clientService;
        if (clientService.recurrent) {
            const nextExecutionDate = new Date(clientService.lastDate);
            nextExecutionDate.setMonth((nextExecutionDate.getMonth()) + clientService.recurrencePeriod);

            commit('SET_NEXT_EXECUTION_DATE', { date: nextExecutionDate });
        }
    },
    closeScheduleDialog({ commit }: ActionContext<ClientsServicesSchedulesState, IRootState>) {
        commit('SET_DEFAULT_SELECTED_CLIENTS_SERVICES_SCHEDULES');
        commit('SET_NEXT_EXECUTION_DATE', { date: new Date() });
        commit('SET_SCHEDULE_DIALOG', { show: false });
    },


    async rescheduleService({ state, dispatch }: ActionContext<ClientsServicesSchedulesState, IRootState>) {
        try {
            const ids = state.itemsSelected.map(item => item.id);
            const data = {
                ids,
                date: state.selectedClientsServicesSchedules.date
            }
            await axiosAuth.put(`${API_URL}/clients_services_schedules/reschedule`, data);

            notifySuccess('Serviço reagendado com sucesso');
            dispatch('closeRescheduleDialog');
            dispatch('fetchServicesSchedules');
        } catch (error) {
            notifyError('Erro');
            console.error('Erro ao reagendar serviço:', error);
        }
    },
    async scheduleNextEvent({ commit, state, dispatch }: ActionContext<ClientsServicesSchedulesState, IRootState>) {
        try {
            const ids = state.itemsSelected.map(item => item.id);
            const data = {
                clientServiceIds: ids,
                date: state.nextExecutionDate,
                status: 0,
            };
            await axiosAuth.post(`${API_URL}/clients_services_schedules`, data);
            notifySuccess('Próxima execução agendada com sucesso!');

            commit('SET_SCHEDULE_DIALOG', { show: false });

            dispatch('fetchServicesSchedules');
        } catch (error) {
            console.error('Erro ao agendar próxima execução:', error);
            notifyError('Erro ao agendar próxima execução');
        }
    },
    async finishService({ commit, state, dispatch }: ActionContext<ClientsServicesSchedulesState, IRootState>, payload: {newSchedule: boolean}) {
        try {
            const ids = state.itemsSelected.map(item => item.id);
            const data = {
                clientServiceScheduleIds: ids,
                lastDate: formatDateToString(new Date()),
                newSchedule: payload.newSchedule,
                nextDate: state.nextExecutionDate,
            };
            await axiosAuth.put(`${API_URL}/clients_services_schedules/finish`, data);
            notifySuccess('Serviço concluído com sucesso');
            commit('SET_SCHEDULE_DIALOG', { show: false });
            dispatch('fetchServicesSchedules')


        } catch (error) {
            console.error('Erro ao finalizar o evento:', error);
            notifyError('Erro ao finalizar o evento');
        }
    },

    async fetchServicesSchedules({ commit }: ActionContext<ClientsServicesSchedulesState, IRootState>, payload: { date: string }) {
        try {
            commit('SET_LOADING', { loading: true });
            const date = payload?.date || getDateFromUrl()

            if (!date) {
                notifyError('Data inválida');
                return;
            }
            const response = await axiosAuth.get<DefaultResponse<ClientsServicesSchedules>>(`${API_URL}/clients_services_schedules/day?date=${date}`);
            commit('SET_SERVICES_SCHEDULES', Object.values(response.data.data));
        } catch (error) {
            notifyError('Erro ao buscar serviços agendados');
            console.error('Erro ao buscar serviços agendados:', error);
        } finally {
            commit('SET_LOADING', { loading: false });
        }
    },

};



export default {
    namespaced: true,
    state,
    mutations,
    actions
};
