import { RouteRecordRaw } from 'vue-router';

const serviceOrderRoutes : Array<RouteRecordRaw> = [
    {
        path: '/services_orders',
        name: 'services_orders',
        component: () => import( './views/ServiceOrderView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default serviceOrderRoutes;