import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import authRoutes from '@/modules/auth/routes'
import clientRoutes from '@/modules/client/routes'
import store from '@/store'
import dashboardRoutes from '@/modules/dashboard/routes'
import emplyeeRoutes from '@/modules/employee/routes'
import serviceRoutes from '@/modules/services/routes'
import stockRoutes from '@/modules/stock/routes'
import clientsServicesSchedulesRoutes from '@/modules/clients_services_schedule/routes'
import avaliationsRoutes from '@/modules/avaliations/routes'
import responsiblesRoutes from '@/modules/responsibles/routes'
import timeCardsRoutes from '@/modules/time_cards/routes'
import registerPointRoutes from '@/modules/register_point/routes'
import employeeSubstitutionsRoutes from '@/modules/employee_substitutions/routes'
import clientRoutesRoutes from '@/modules/client_routes/routes'
import serviceOrderRoutes from '@/modules/service_order/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../modules/dashboard/views/HomeView.vue'),
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "about" */ '../modules/dashboard/views/ResetPassword.vue'),
  },
  ...authRoutes,
  ...avaliationsRoutes,
  ...clientRoutes,
  ...dashboardRoutes,
  ...emplyeeRoutes,
  ...registerPointRoutes,
  ...serviceRoutes,
  ...stockRoutes,
  ...clientsServicesSchedulesRoutes,
  ...responsiblesRoutes,
  ...timeCardsRoutes,
  ...employeeSubstitutionsRoutes,
  ...clientRoutesRoutes,
  ...serviceOrderRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

                        
router.beforeEach(async (to, from, next) => {
  if (to.meta?.requiresAuth) {
    const logged = store.getters["auth/isLogged"]
    if (logged) {
      if (!store.getters["auth/isTokenExpired"]) {
        next()
      } else if (store.getters["auth/stayConnected"]) {
        store.dispatch("auth/refreshToken", { route: to })
      } else {
        store.dispatch("auth/unauthorized")
      }
    } else {
      await store.dispatch("auth/recoverToken")
      const logged = store.getters["auth/isLogged"]
      if (logged) {
        next()
      } else {
        router.push({ name: 'login' })
      }
    }
  } else {
    next()
  }
})

export default router
