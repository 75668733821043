import { ActionContext } from 'vuex';
import { useNotification } from '@/composables/useNotification';
import { Employee, IFilterByClient, IFilterEmployee } from '../types/employee';
import { EmployeeState } from './state';
import { FormMode } from '@/consts/form';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { DefaultResponse } from '@/types/response';
import router from '@/router';
import { defaultEmployee, defaultFilterEmployee } from '../consts';
import { IRootState } from '@/types/state';


const { notifySuccess, notifyError } = useNotification();

const state: () => EmployeeState = () => ({
  employees: [],
  filteredEmployees: [],
  filterEmployees: Object.assign({}, defaultFilterEmployee),
  formMode: FormMode.ADD,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  selectedEmployee: defaultEmployee,
  showDeleteDialog: false,
  showFormDialog: false,
  showFilterDialog: false,
});


const mutations = {
  RESET_EMPLOYEE_FILTER(state: EmployeeState) {
    state.filterEmployees = Object.assign({}, defaultFilterEmployee);
  },
  SET_EMPLOYEES(state: EmployeeState, employees: Employee[]) {
    state.employees = employees;
    state.filteredEmployees = employees;
  },
  SET_FILTERED_EMPLOYEES(state: EmployeeState, filteredEmployees: Employee[]) {
    state.filteredEmployees = filteredEmployees;
  },
  SHOW_FORM_DIALOG(state: EmployeeState, payload: { show: boolean }) {
    state.showFormDialog = payload.show;

  },
  SET_FILTER_DIALOG(state: EmployeeState, payload: { show: boolean }) {
    state.showFilterDialog = payload.show;
  },
  SET_FILTER_EMPLOYEE(state: EmployeeState, payload: { filter: IFilterEmployee }) {
    state.filterEmployees = payload.filter;
  },
  SET_SELECTED_EMPLOYEE(state: EmployeeState, payload: { employee: Employee }) {
    state.selectedEmployee = Object.assign({}, payload.employee);
  },
  SET_FORM_MODE(state: EmployeeState, payload: { mode: FormMode }) {
    state.formMode = payload.mode;
  },
  SET_TOTAL_ITEMS(state: EmployeeState, payload: { total: number }) {
    state.pagination.total = payload.total;
  },
  SHOW_DELETE_DIALOG(state: EmployeeState, payload: { show: boolean }) {
    state.showDeleteDialog = payload.show;
  },
};

const getters = {
  getCurrentEmployeeId: (state: EmployeeState) => {
    return state.selectedEmployee.id;
  },
};

const actions = {
  clearFilter({ commit, state }: ActionContext<EmployeeState, IRootState>) {
    commit('SET_FILTER_EMPLOYEE', {filter: Object.assign({}, defaultFilterEmployee)});
  },
  openFilterDialog({ commit }: ActionContext<EmployeeState, IRootState>) {
    commit('SET_FILTER_DIALOG', {show: true});
  },
  closeFilterDialog({ commit }: ActionContext<EmployeeState, IRootState>) {
    commit('SET_FILTER_DIALOG', {show:false});
  },
  openCreateDialog({ commit }: ActionContext<EmployeeState, IRootState>) {
    commit('SET_FORM_MODE', { mode: FormMode.ADD });
    commit('SET_SELECTED_EMPLOYEE', { employee: defaultEmployee });
    commit('SHOW_FORM_DIALOG', { show: true });
  },
  openEditDialog({ commit }: ActionContext<EmployeeState, IRootState>, payload: { employee: Employee }) {
    commit('SET_FORM_MODE', { mode: FormMode.EDIT });
    commit('SET_SELECTED_EMPLOYEE', payload);
    commit('SHOW_FORM_DIALOG', { show: true });
  },
  openScreenDetails({ commit }: ActionContext<EmployeeState, IRootState>, payload: { employee: Employee }) {
    router.push({ name: 'employeeDetails', params: { id: payload.employee.id } });
    
    commit('SET_SELECTED_EMPLOYEE', payload.employee);
  },
  openViewDialog({ commit }: ActionContext<EmployeeState, IRootState>, payload: { employee: Employee }) {
    commit('SET_FORM_MODE', { mode: FormMode.VIEW });
    commit('SET_SELECTED_EMPLOYEE', payload);
    commit('SHOW_FORM_DIALOG', { show: true });
  },
  openDeleteDialog({ commit }: ActionContext<EmployeeState, IRootState>, payload: { employee: Employee }) {
    commit('SHOW_DELETE_DIALOG', { show: true });
    commit('SET_SELECTED_EMPLOYEE', payload);
  },
  async createEmployee({ dispatch, state }: ActionContext<EmployeeState, IRootState>) {
    try {
      await axiosAuth.post(`${API_URL}/employees`, state.selectedEmployee);
      notifySuccess('Colaborador criado com sucesso');
      dispatch('fetchEmployees');
    } catch (error) {
      notifyError('Erro ao criar colaborador');
    }
  },
  async updateEmployee({ dispatch, state }: ActionContext<EmployeeState, IRootState>) {
    try {
      await axiosAuth.put(`${API_URL}/employees/${state.selectedEmployee.id}`, state.selectedEmployee);
      notifySuccess('Colaborador atualizado com sucesso');
      dispatch('fetchEmployees');
    } catch (error) {
      notifyError('Erro ao atualizar colaborador');
    }
  },
  async fetchEmployees({ commit, state }: ActionContext<EmployeeState, IRootState>, payload: { filterByClient?: IFilterByClient } = {}) {
    try {
      const params: any = {};

      for (const key in state.filterEmployees) {
        const value = state.filterEmployees[key as keyof typeof state.filterEmployees];
        if (value || value === 0 || value === false) {
          params[key] = value;
        }
      }

      if (payload.filterByClient) {
        params.latitude = payload.filterByClient.address.latitude;
        params.longitude = payload.filterByClient.address.longitude;
        params.minFreeHours = payload.filterByClient.minFreeHours;
        params.startHour = payload.filterByClient.startHour;
        params.finishHour = payload.filterByClient.finishHour;
        params.days = payload.filterByClient.days.join(',');
        if(payload.filterByClient.currentEmployees && payload.filterByClient.currentEmployees.length > 0){
          params.currentEmployees = payload.filterByClient.currentEmployees.join(',');
        }
      }
      params.page = state.pagination.page;
      params.itemsPerPage = state.pagination.itemsPerPage;
      const response = await axiosAuth.get<DefaultResponse<Employee>>(`${API_URL}/employees`, { params });
      console.log(response.data)
      commit('SET_TOTAL_ITEMS', { total: response.data.total });
      commit('SET_EMPLOYEES', response.data.data);
    } catch (error) {
      console.log(error)
      notifyError('Erro ao buscar colaboradores');
    }
  },
  async deleteEmployee({ commit, dispatch, state }: ActionContext<EmployeeState, IRootState>) {
    try {
      await axiosAuth.delete(`${API_URL}/employees/${state.selectedEmployee.id}`);
      notifySuccess('Colaborador deletado com sucesso');
      dispatch('fetchEmployees');
      commit('SHOW_DELETE_DIALOG', { show: false });
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao deletar colaborador:', error);
    }
  },
  async fetchEmployeeById({ commit }: ActionContext<EmployeeState, IRootState>, payload: { id: string }) {
    try {
      const response = await axiosAuth.get<DefaultResponse<Employee>>(`${API_URL}/employees/${payload.id}`);
      commit('SET_SELECTED_EMPLOYEE', { employee: response.data.data });
    } catch (error) {
      notifyError('Erro ao buscar colaborador');
    }
  }
};



export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
